import { Component } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { environment } from '@frontend/env/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(private title: Title, private meta: Meta) {
    this.title.setTitle(environment.siteTitle);
    this.meta.addTag({ property: 'og:image', content: `${environment.image}` });
    const exp = localStorage.getItem('exp');
    if (Number(exp) && new Date() > new Date(Number(exp) * 1000)) {
      const property = ['token', 'exp', 'roles', 'ipAddress', 'profile'];
      property.forEach((item: string) => {
        localStorage.removeItem(item);
      });
      window.location.href = '/auth/login';
    }
  }
}
